<template>
    <div></div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    beforeCreate() {},
    beforeDestroy() {},
    mounted() {
        var path = this.$route.query.path || 'pages/home/home';
        var params = this.$route.query.params || '';
        var url = `/ms-pocket-hospital/v1/live/getScheme/live?path=${path}&params=${params}`;
        console.log(url, 'url');
        this.$axios.get(url).then(res => {
            //
            var secheme = res.data.results;
            console.log(res);
            window.location.href = secheme;
            window.close();
        });
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
